.description,
.sponsor {
  @apply text-sm;
  max-width: 114ex;
  a {
    @apply underline decoration-1 decoration-dotted underline-offset-2;
  }
}
.sponsor {
  @apply font-medium;
}

/* Hide left border for the first item */
.card:first-child {
  @apply border-l-0;
}

@media (max-width: 640px) {
  .card:first-child > div {
    @apply border-t-0;
    // @apply bg-green-100;
  }
}
/* Tablet view - applies only for 3-column grid between 640px and 1023px */
@media (min-width: 640px) and (max-width: 1023px) {
  .card:nth-child(3n + 1) {
    @apply border-l-0;
    // @apply bg-green-100;
  }
  // Apply styles to the first 3 items
  .card:nth-child(-n + 3) > div {
    // @apply bg-red-100;
    @apply border-t-0;
    border-top: 0;
  }
}

/* Desktop view - applies only for 4-column grid from 1024px and up */
@media (min-width: 1024px) {
  .card:nth-child(4n + 1) {
    @apply border-l-0;
    // @apply bg-red-100;
  }
  // Apply styles to the first 4 items
  .card:nth-child(-n + 4) > div {
    // @apply bg-red-100;
    @apply border-t-0;
    border-top: 0;
  }
}

/* Default card styling */
.card {
  // @apply bg-blue-400;
}
